import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import { mapState, mapGetters, mapMutations } from "vuex";
import Mixin from "@/utils/mixin";
import { columns } from "./config/index";
import { LotteryApi } from "@/api";
import { download } from '@/utils/util';
export default {
  name: "bannerManagement",
  components: {},
  data: function data() {
    return {
      page: 1,
      size: 10,
      loading: false,
      dataSource: [],
      columns: columns,
      total: 0,
      stateEnum: {
        0: "进行中",
        1: "待开始",
        2: "已完结",
        3: "已禁用"
      },
      previewImage: '',
      previewVisible: false
    };
  },
  mixins: [Mixin],
  computed: {},
  created: function created() {
    this.getList();
  },
  activated: function activated() {
    // console.log('activated')
    this.getList();
  },
  methods: {
    // 新增
    handleAdd: function handleAdd() {
      this.$router.push({
        path: "/lottery/add"
      });
    },
    // 统计数据
    handleStatistic: function handleStatistic() {
      var url = LotteryApi.activityExportURL();
      console.log(url);
      download(url);
    },
    // 获取列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      var _params = {
        size: this.size,
        page: this.page
      };
      LotteryApi.findpage(_params).then(function (res) {
        _this2.loading = false;
        _this2.dataSource = res.list;
        _this2.total = res.count;
      }).catch(function (err) {
        _this2.dataSource = [];
      }).finally(function () {
        setTimeout(function () {
          _this2.loading = false;
        }, 3000);
      });
    },
    handleDetail: function handleDetail(record, type) {
      var _this = this;
      if (type == 1) {
        _this.$router.push("/lottery/edit?id=".concat(record.id));
      } else if (type == 2) {
        _this.$router.push("/lottery/view?id=".concat(record.id));
      } else if (type == 3 || type == 4) {
        _this.$confirm({
          icon: function icon() {
            return false;
          },
          title: '注意',
          content: type == 3 ? '确定禁用？' : '确认启用？',
          width: '420px',
          class: 'myconfirm',
          onOk: function onOk() {
            _this.loading = true;
            LotteryApi.forbidden({
              id: record.id
            }).then(function (res) {
              _this.getList();
            }).catch(function (res) {
              _this.loading = false;
            }).finally(function () {
              _this.loading = false;
            });
          }
        });
      } else if (type == 5) {
        _this.$confirm({
          icon: function icon() {
            return false;
          },
          title: '注意',
          content: '本操作不可逆，确认立即开奖？',
          width: '420px',
          class: 'myconfirm warning',
          onOk: function onOk() {
            _this.loading = true;
            LotteryApi.runLottery({
              id: record.id
            }).then(function (res) {
              _this.getList();
            }).catch(function (res) {
              _this.loading = false;
            }).finally(function () {
              _this.loading = false;
            });
          }
        });
      }
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    preview: function preview() {
      var previewImage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      this.previewImage = previewImage;
      this.previewVisible = true;
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
    }
  }
};